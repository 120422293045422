import { addNewRelicPageAction } from 'ddc-new-relic';

const wsTrackNr = (eventType) => {
	if (sessionStorage?.getItem('wsTrackingUser') === 'track') {
		addNewRelicPageAction('wsTrackingDebug', {
			eventName: eventType,
			userSessionId: sessionStorage?.getItem('wsTrackingSessionId')
		});
	}
};

export const wsTrackNrClickPathMissing = () => {
	addNewRelicPageAction('wsTrackingClickPathMissing', {
		eventName: 'ddc.form.confirmation',
		userSessionId: sessionStorage?.getItem('wsTrackingSessionId')
	});
};

export default wsTrackNr;
